import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import BioImage from "../components/images/bio"

const IndexPage = () => (
  <Layout imageVariation="1">
    <SEO title="Biography" />
    <h3 className="uppercase font-bold text-4xl mb-4 hidden lg:block">
      {" "}
      Biography
    </h3>
    <p className="mb-4">
    Because Of Art is the moniker of London based producer Alex Grover, who has very quickly established himself as one of dance music's hottest new talents, hailing attention from ravers, DJs and tastemakers alike. 
    </p>
    <p className="mb-4">
    Alex's most recent success comes from his breaks influenced track 'Elevate' released on tastemaker Danny Howard's Nothing Else Matters label. The 'Generation Z EP' highlighted three new producers tipped as the next generation of dance music. Elevate went on to spend over 6 weeks on top of the Beatport Leftfield House & Techno chart whilst receiving strong DJ and BBC Radio 1 support, most notably from dance music legend Sasha. The past 12 months have also seen strong releases on key record labels such as Stress Records and Gruuv Recordings. 
    </p>
    {/* <BioImage /> */}
    <p className="mb-4">
    Outside of the studio, Because of Art is a growing name on the UK DJ circuit, most notably sharing bills with the likes of Sven Väth, Dusky, Eats Everything and many more at Motion Bristol. 
    </p>
    <p className="mb-4">
    With the hopeful return of rave and club culture, 2021 is shaping up to be yet another breakthrough year for Because Of Art.
    </p>
    <p className="mb-4">
    For enquiries please contact matt@neptunemgmt.co.uk
    </p>
  
  </Layout>
)

export default IndexPage
